.chat-container {
  background-color: #fff;
  display: flex;
  align-items: stretch;
  .chat-sidebar {
    box-shadow: 2px 6px 11px 0px rgba(166, 166, 166, .8);
    margin: 10px;
    background-color: #fff;
    padding: 10px;
    border-radius: 4px;
    height: 98%;
    width: 20%;
    .chat-filter-box {
      .chat-filter-input {
        width: 100%;
        margin-bottom: 10px;
      }
      .chat-buttons-filter {
        display: flex;
        margin-bottom: 10px;
        justify-content: space-between;
        &__btn {
          font-size: 12px;
          width: 50%;
        }
      }
    }
    .chat-user-list {
      overflow: auto;
      height: 90%;
      .chat-user-item {
        margin-bottom: 10px;
        cursor: pointer;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #efefef;
        &--selected {
          background-color: #f2f2f2;
        }
        .ant-card-head {
          min-height: 20px;
          padding: 0 10px;
          border-bottom: 0;
          .ant-card-head-title, .ant-card-extra {
            padding: 0;
          }
        }
        .ant-card-body {
          padding: 5px;
        }
        &__online-status {
          &--online {
            color: #22bb48;
            height: 0.6em;
          }
          &--offline {
            color: #ccc;
          }
        }
        &__message {
          font-size: 12px;
          font-style: italic;
        }
      }
    }
  }
  .chat-message {
    flex-grow: 2;
    height: 98%;
    background-color: #fff;
    box-shadow: 2px 6px 11px 0px rgba(166, 166, 166, .8);
    border-radius: 4px;
    margin: 10px;
    .chat-message-header {
      display: flex;
      padding: 10px;
      border-bottom: 2px solid #ccc;
      &__userinfo {
        flex-grow: 2;
        justify-items: center;
        justify-content: center;
        &__avatar {
          width: 60px;
          height: 60px;
          object-position: center center;
          object-fit: cover;
          border-radius: 100%;
          border: none;
          margin-right: 10px;
        }

        &__username {
          align-self: center;
          font-weight: bold;
          text-transform: capitalize;
          margin-right: 10px;
        }

        &__account-type {
          align-self: center;
          border: 1px solid #ccc;
          border-radius: 4px;
          background-color: #ccc;
          color: #000;
          display: inline-block;
          padding: 2px 5px;
          margin-right: 10px;
        }

        &__txt {

        }
      }
      &__target {
        align-self: center;
        &__value {
          border: 1px solid #ccc;
          border-radius: 20px;
          padding: 5px 10px;
        }
      }
    }
    .chat-message-list {
      height: calc(100vh - 380px);
      overflow: auto;
      padding: 10px;
      .chat-message-item {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        &__avatar {
          width: 30px;
          height: 30px;
          object-position: center center;
          object-fit: cover;
          border-radius: 100%;
          border: none;
          margin-right: 10px;
        }
        &__message {
          display: flex;
          align-items: center;
          gap: 5px;
          .text {
            border: 1px solid #ccc;
            padding: 5px 15px;
            border-radius: 20px;
          }
          .time {
            font-size: 8px;
            font-style: italic;
          }
        }
        &--not-me {
          text-align: left;
        }
        &--it-me {
          justify-content: flex-end;
          .chat-message-item__message {
            flex-flow: row-reverse;
            .text {
              background-color: #0084ff;
              color: #fff;
              border: none;
            }
          }
        }
      }
    }

    .chat-message-input-box {
      padding: 10px;
      margin-top: 30px;
      .chat-message-helper {
        margin-bottom: 10px;
      }
      .chat-message-input {
        resize: none;
        height: 120px;
        border-radius: 4px;
      }
    }
  }

}
